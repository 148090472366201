import React, { lazy, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import CookieBanner from 'components/atoms/cookie-banner';

const CareersPage = lazy(() => import('components/pages/careers'));
const VacancyPage = lazy(() => import('components/pages/careers-vacancy'));
const CareersInterviewPage = lazy(() =>
    import('components/pages/careers-interview')
);
const CareersInterviewSuccessPage = lazy(() =>
    import('components/pages/careers-interview-success')
);
const PrivacyPolicyPage = lazy(() => import('components/pages/privacy-policy'));
const CookiePolicyPage = lazy(() => import('components/pages/cookie-policy'));
const Homepage = lazy(() => import('components/pages/homepage'));

const App = () => {
    return (
        <Router>
            <Suspense fallback={<div></div>}>
                <Switch>
                    <Route path="/careers/:id" component={VacancyPage} />
                    <Route path="/careers" component={CareersPage} />
                    <Route
                        path="/careers-interview"
                        component={CareersInterviewPage}
                    />
                    <Route
                        path="/careers-interview-success"
                        component={CareersInterviewSuccessPage}
                    />
                    <Route
                        path="/privacy-policy"
                        component={PrivacyPolicyPage}
                    />
                    <Route path="/cookie-policy" component={CookiePolicyPage} />
                    <Route path="/" component={Homepage} />
                    <Redirect to="/" />
                </Switch>
            </Suspense>
            <CookieBanner />
        </Router>
    );
};

export default App;
